import { AxiosResponse } from 'axios'

import { _axios } from './axios'

const SECURITY_REVIEW_CLEAN_UP = '/workflow/security-review-cleanup'

export const workFlowApi = () => {
  const securityReviewCleanup = async (): Promise<
    AxiosResponse<{ orderMsg: string; securityMsg: string; assignMsg: string }>
  > => {
    return await _axios()
      .post(SECURITY_REVIEW_CLEAN_UP)
      .then((res) => {
        const data = {
          orderMsg: res.data.orderMsg,
          securityMsg: res.data.orderMsg,
          assignMsg: res.data.assignMsg,
        }

        res.data = data
        return res
      })
  }

  return {
    securityReviewCleanup,
  }
}
